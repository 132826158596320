<template>
  <v-bottom-navigation
    v-if="createBottomNavigation"
    app
    dark
    :style="{ zIndex: $zIndexConfig.formAction }"
    class="grey darken-2"
  >
    <formActionStatus v-if="hasWritePermission" :form-key="formKey"></formActionStatus>
    <formActionSave v-if="hasWritePermission" :form-key="formKey"></formActionSave>
    <formActionRefresh :form-key="formKey"></formActionRefresh>
    <formActionBack :form-key="formKey"></formActionBack>
    <formActionDelete v-if="hasWritePermission" :form-key="formKey"></formActionDelete>

    <template v-if="customDataAction">
      <customDataAction
        v-for="(customDataActionConfig, key) in customDataAction"
        :key="key"
        :formKey="formKey"
        :config="customDataActionConfig"
      ></customDataAction>
    </template>
  </v-bottom-navigation>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    formKey: String,
  },
  computed: {
    hasWritePermission() {
      return this.$store.getters[`form/${this.formKey}/hasWritePermission`]
    },
    createBottomNavigation() {
      if(!this.formConfig) return true
      return this.formConfig.disabledBottomNavigation != false
    },
    formConfig() {
      return this.$store.getters[`form/${this.formKey}/config`]
    },
    customDataAction() {
      return this.formConfig.customDataAction
    },
  },
  components: {
    formActionStatus: () => import('@/components/form/formAction/status.vue'),
    formActionSave: () => import('@/components/form/formAction/save.vue'),
    formActionRefresh: () => import('@/components/form/formAction/refresh.vue'),
    formActionBack: () => import('@/components/form/formAction/back.vue'),
    formActionDelete: () => import('@/components/form/formAction/delete.vue'),
    customDataAction: () => import('@/components/form/formAction/customDataAction.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
